import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import { useState } from '@hookstate/core'
import { FollowModalState, StrategyAction, StrategyState } from "../../state"
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { Paper, Toolbar } from '@material-ui/core'
import CONF from '../../../../config'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      width: theme.spacing(50),
      height: theme.spacing(30),
      // backgroundColor: theme.palette.background.paper,
      // // border: '2px solid #000',
      // boxShadow: theme.shadows[5],
      // padding: theme.spacing(2, 4, 3),
      outline: 'none',
    },
    paperToolbar: {
      backgroundColor: CONF.css.grey,
      width: '100%',
      // height: theme.spacing(1),
    },
    confirmTypo: {
      fontWeight: 700,
    },
    mainTypo: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(5),
    },
    noButton: {
      marginRight: theme.spacing(10),
    },
  })
)

export default function UnfollowModalOutside() {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const unfollowModalOpenState = useState(FollowModalState.unfollowOpen)
  const unfollowModalStrategyIdState = useState(FollowModalState.strategyId)
  const strategyTimeState = useState(StrategyState.time)

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={unfollowModalOpenState.get()}
      onClose={() => {
        unfollowModalOpenState.set(false)
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={unfollowModalOpenState.get()}>
        <Paper className={classes.paper}>
          <Toolbar variant="dense" className={classes.paperToolbar}>
            <Typography className={classes.confirmTypo}>{t('Confirm')}</Typography>
          </Toolbar>

          <Grid container item justify="center" style={{ padding: '0px 30px' }}>
            <Typography className={classes.mainTypo}>{t('Are you sure unfollowing this strategy?')}</Typography>
          </Grid>

          <Grid container item justify="center">
            <Button
              className={classes.noButton}
              onClick={() => {
                unfollowModalOpenState.set(false)
              }}
            >
              {t('No')}
            </Button>
            <Button
              onClick={() => {
                unfollowModalOpenState.set(false)
                StrategyAction.unfollow(unfollowModalStrategyIdState.get())().then(() => {
                  // StrategyAction.findAndCountEquity({}, true)()
                  strategyTimeState.set(+new Date())
                  // window.location.reload()
                })
              }}
            >
              {t('Yes')}
            </Button>
          </Grid>
        </Paper>
      </Fade>
    </Modal>
  )
}
