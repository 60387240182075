import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Paper } from '@material-ui/core'
import { navigate } from 'gatsby'
import { useState } from '@hookstate/core'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { FollowModalState, StrategyAction, StrategyState } from "../../state"
import { CONST, ROUTES } from '../../../../constant'
import CardChart from './CardChart'
import { getObj } from '../../../../utils/hookState'
import { AccountState } from '../../../Account/state'
import { isObjEmpty } from '../../../../utils/parse'
import { useStyles } from './style'
import { getLoginInfo } from '../../../../utils/localStorage'
import { useTranslation } from 'react-i18next'
import { LanguageState } from "../../../Layout/Header/state";

export default function SingleStrategyCard({ record, subscribeList }) {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const unfollowOpenState = useState(FollowModalState.unfollowOpen)
  const publicFollowOpenState = useState(FollowModalState.publicFollowOpen)
  const modalStrategyIdState = useState(FollowModalState.strategyId)
  const strategyTimeState = useState(StrategyState.time)
  const languageState = useState(LanguageState.language)

  // console.log(`=====record?.accumulated_profit==${record.id}====${record?.accumulated_profit}`)
  // console.log(`=====isObjEmpty(getObj(loginState))======${isObjEmpty(getObj(loginState))}`)

  return (
    <Paper className={classes.card} elevation={3}>
      <Typography className={classes.titleTypo} onClick={() => navigate(`${ROUTES.STRATEGY}/${record.id}`)}>
        {languageState.get() === 'en' ? record.name : (record.titleVi != null ? record.titleVi : record.name )}
      </Typography>
      <div style={{ width: '100%' }}>
        <CardChart chartDataList={record.chartDataList} type={record.type} />
      </div>
      <Grid container item>
        <Grid container item xs={6} justify={'flex-start'} alignItems={'center'}>
          <Typography className={classes.ratio}>ROI</Typography>

          {record.type === CONST.STRATEGY_TYPE.EQUITY ? (
            <div className={record?.accumulated_profit >= 1 ? classes.roiDiv : classes.redRoiDiv}>
              <Typography className={record?.accumulated_profit >= 1 ? classes.ratioValue : classes.redRatioValue}>
                {((record?.accumulated_profit - 1) * 100)?.toFixed(2)}%
              </Typography>
            </div>
          ) : (
            <div className={record?.accumulated_profit >= 0 ? classes.roiDiv : classes.redRoiDiv}>
              <Typography className={record?.accumulated_profit >= 0 ? classes.ratioValue : classes.redRatioValue}>
                {record?.accumulated_profit?.toFixed(2)} p
              </Typography>
            </div>
          )}

          <Typography className={classes.ratio}>{t('Users Following')} </Typography>
          <div className={classes.subscribeDiv}>
            <Typography className={classes.drawdownValue}>{record.subscribers}</Typography>
          </div>
        </Grid>
        <Grid container item xs={6} justify={'flex-start'} alignItems={'center'}>
          <Typography className={classes.ratio}>{t('Drawdown')}</Typography>

          <div className={classes.drawdownDiv}>
            {record.type === CONST.STRATEGY_TYPE.EQUITY ? (
              <Typography className={classes.drawdownValue}>-{record?.max_drawdown?.toFixed(2)}%</Typography>
            ) : (
              <Typography className={classes.drawdownValue}>-{record?.max_drawdown?.toFixed(2)} p</Typography>
            )}
          </div>

          <Typography className={classes.ratio}>{t('Views')}</Typography>
          <div className={classes.subscribeDiv}>
            <Typography className={classes.drawdownValue}>{record.views}</Typography>
          </div>
        </Grid>
      </Grid>
      {subscribeList != null && subscribeList.includes(record.id) ? (
        <Button
          size="small"
          variant={'contained'}
          className={classes.followingButton}
          onClick={() => {
            modalStrategyIdState.set(record.id)
            unfollowOpenState.set(true)
            // StrategyAction.unfollow(record.id)().then(() => {
            //   StrategyAction.findAndCount({}, true)()
            //   // window.location.reload()
            // })
          }}
        >
          {t('Following')}
        </Button>
      ) : (
        <Button
          size="small"
          variant={'contained'}
          className={classes.followButton}
          onClick={() => {
            if (!isObjEmpty(getLoginInfo())) {
              // console.log(`=====loginState2======${JSON.stringify(getLoginInfo())}`)

              StrategyAction.follow(record.id)().then((response) => {
                if (response !== 'fail') {
                  // StrategyAction.findAndCountEquity({}, true)()
                  strategyTimeState.set(+new Date())

                  // window.location.reload()
                }
              })
            } else {
              modalStrategyIdState.set(record.id)
              publicFollowOpenState.set(true)
            }
          }}
        >
          {t('Follow')}
        </Button>
      )}
    </Paper>
  )
}
