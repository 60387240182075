import { createStyles, makeStyles } from '@material-ui/core/styles'
import CONF from '../../../config'

export const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      // minWidth: 650,
      // tableLayout: 'fixed',
    },
    pagination: {},
    customPagination: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    tableFooter: {
      // left: 0,
      // bottom: 0, // <-- KEY
      // zIndex: 2,
      // position: 'sticky'
    },
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
      margin: theme.spacing(1),
      borderRadius: theme.spacing(1),
    },
    gridList: {
      width: '100%',
      // flexWrap: 'nowrap',
      // height: 450,
    },
    card: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      // overflow: 'hidden',

      // padding: theme.spacing(2),
      margin: theme.spacing(0.5),
      // height: theme.spacing(40),
      borderRadius: theme.spacing(3),
    },
    icon: {
      color: 'rgba(255, 255, 255, 0.54)',
    },
    titleGrid: {
      marginTop: theme.spacing(2),
      // marginBottom: theme.spacing(2),
    },
    header: {
      fontSize: '16px',
      fontWeight: 600,
      color: 'rgb(71,71,71)',
      // marginTop: theme.spacing(2),
      fontFamily: ['OpenSans', 'sans-serif'].join(','),
    },
    subTitle: {
      fontSize: '12px',
      fontWeight: 600,
      color: 'rgb(129,129,129)',
      // marginBottom: theme.spacing(1),
      fontFamily: ['OpenSans', 'sans-serif'].join(','),

      // marginRight: theme.spacing(2),
    },
    viewMore: {
      color: 'rgb(40, 159, 235)',
      fontSize: '12px',
      fontWeight: 600,
      fontFamily: ['OpenSans', 'sans-serif'].join(','),
      cursor: 'pointer',
    },
    viewMoreGrid: {
      paddingRight: theme.spacing(3),
    },
    arrowDiv: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    leftArrow: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      // color: 'rgb(153,153,153)',
      // cursor: 'pointer',
    },
    rightArrow: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      // color: 'rgb(153,153,153)',
      // cursor: 'pointer',
    },
    singleCardDiv: {
      display: 'block',
      width: theme.spacing(100)
    },
    divider: {
      marginBottom: theme.spacing(2),
    },
    media: {
      height: 140,
      // height: 0,
      // paddingTop: '56.25%', // 16:9,
      // marginTop:'30'
    },
    titleTypo: {
      fontSize: '15px',
      fontWeight: 700,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
      cursor: 'pointer',
    },
    followButton: {
      backgroundColor: 'rgb(255,114,0)',
      color: CONF.css.white,
      borderRadius: 20,
      width: 200,
      margin: theme.spacing(2),
      '&:hover': {
        //you want this to be the same as the backgroundColor above
        backgroundColor: 'rgb(255,114,0)',
      },
      marginBottom: theme.spacing(3),
    },
    followingButton: {
      backgroundColor: CONF.css.whiteGrey,
      color: CONF.css.white,
      borderRadius: 20,
      width: 200,
      margin: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
    ratio: {
      display: 'block',
      width: '100%',
      fontSize: '11px',
      fontWeight: 400,
      marginLeft: theme.spacing(2),
      fontFamily: ['OpenSans', 'sans-serif'].join(','),
    },
    roiDiv: {
      display: 'flex',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      width: '100%',
      height: theme.spacing(3),
      backgroundColor: 'rgb(234,251,236)',
      alignItems: 'center',
    },
    drawdownDiv: {
      display: 'flex',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      height: theme.spacing(3),
      width: '100%',
      backgroundColor: 'rgb(241,241,241)',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    subscribeDiv: {
      display: 'flex',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      height: theme.spacing(3),
      width: '100%',
      // backgroundColor: 'rgb(241,241,241)',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    ratioValue: {
      display: 'block',
      color: 'rgb(51,153,66)',
      width: '100%',
      fontSize: '14px',
      fontWeight: 700,
      marginLeft: theme.spacing(1),
    },
    drawdownValue: {
      display: 'block',
      color: 'rgb(119,119,119)',
      width: '100%',
      fontSize: '14px',
      fontWeight: 700,
      marginLeft: theme.spacing(1),
    },
    disclaimer: {
      fontSize: '14px',
      fontWeight: 400,
      margin: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      color: 'rgb(83, 83, 83)',
      fontFamily: ['OpenSans', 'sans-serif'].join(','),
    },
    howToFollow: {
      fontSize: '14px',
      fontWeight: 700,
      margin: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      color: CONF.css.orange,
      fontFamily: ['OpenSans', 'sans-serif'].join(','),
      cursor: 'pointer'
    },
    bottomDisclaimer: {
      fontFamily: ['OpenSans', 'sans-serif'].join(','),
      fontSize: '14px',
      fontWeight: 400,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  })
)
