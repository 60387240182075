// @ts-nocheck
import React from 'react'
import SEO from '../components/seo'
import Layout from '../components/Layout'
import StrategyGridList from '../components/Strategy/StrategyGridList'
import { Router } from '@reach/router'
import { ROUTES } from '../constant'
import StrategyDetail from '../components/Strategy/StrategyDetail'
import { useTranslation } from "react-i18next"

export default () => {
  const { t, i18n } = useTranslation()

  return (
    <Layout>
      <SEO title={t('Strategy')} />
      <Router>
        <StrategyDetail path={`${ROUTES.STRATEGY}/:slug`} />
        <StrategyGridList path={`${ROUTES.STRATEGY}`} />
      </Router>
    </Layout>
  )
}
